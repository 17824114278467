import React from 'react';
import { url_api } from '../../../utils/config';

const MemberCard = ({ member }) => {
    const { first_name, last_name, role, description, image_uri } = member;

    return (
        <div className="col col-12 col-md-6 col-lg-4 col-xl-3 p-3 shadow mt-5">
            <div className='card'>
                <div className="square-image">
                    <img
                        src={`${url_api}/assets/pictures/${image_uri}.png`}
                        alt={`${first_name} ${last_name}`}
                    />
                </div>
                <div className="card-body">
                    <h5 className="card-title">{`${first_name} ${last_name}`}</h5>
                    <p className="card-text"><strong>Rôle:</strong> {role}</p>
                    <p className="card-text"><strong>Description:</strong> {description}</p>
                </div>
            </div>
        </div>
    );
};
export default MemberCard;