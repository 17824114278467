import { useEffect } from "react";
import { useState } from "react";
import { getLang } from "../../../../utils/user";

export default function LinkCell({ element }) {

    const [text, setText] = useState(element.text_content_fr);

    useEffect(() => {
        switch (getLang()) {
            case 'fr':
                setText(element.text_content_fr);
                break;
            case 'nl':
                setText(element.text_content_fr);
                break;
            case 'nl':
                setText(element.text_content_fr);
                break;
        }
    });

    return (
        <a target="_blank" href={element.link_to} style={{ color: element.text_color, fontSize: element.font_size + 'em', textAlign: element.text_align }} className="link">{text}</a>
    )
}