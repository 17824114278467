import Cookies from "js-cookie";

export function getUserConnected() {
    return Cookies.get('idUser');
}

export function userIsConnected() {
    return Cookies.get('idUser') !== undefined;
}

export function disconnect() {
    Cookies.remove('idUser');
    window.location.reload();
}

export function connectUser(idUser) {
    Cookies.set('idUser', idUser, { expires: 3 });
}

export function setLang(lang) {
    Cookies.remove('lang');
    Cookies.set('lang', lang);
}

export function getLang() {
    return Cookies.get('lang');
}

export function existeLang() {
    return getLang() !== undefined;
}