import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './fr.json';
import en from './en.json';
import nl from './nl.json';

i18n
    .use(initReactI18next)
    .init(
        {
            resources: {
                fr: {
                    translation: fr
                },
                en: {
                    translation: en
                },
                nl: {
                    translation: nl
                }
            },
            lng: "fr",
            interpolation: {
                escapeValue: false
            }
        });

console.log(i18n);

export default i18n;