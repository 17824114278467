import React, { useState } from 'react';
import ModalCustom from '../../../Repeats/Modal';
import { requestApi } from '../../../../utils/request';
import { connectUser } from '../../../../utils/user';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [btn, setBtn] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Email:', email);
        console.log('Password:', password);
        setBtn(true);

        const res = await requestApi('/user/login', 'post', {
            email: email,
            password: password
        })

        if (!res.data.success) {
            setModalContent(res.data.msg);
            setModalShow(true);
            setBtn(false);
        } else {
            connectUser(res.data.data.userId);
            window.location.href = '/';
        }
    };

    return (
        <>
            <ModalCustom show={modalShow} content={modalContent} onHide={() => { setModalShow(false); }} />
            <div className="container mt-5">
                <div className="mx-auto form-custom rounded p-3" style={{ maxWidth: '400px' }}>
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">Connexion</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="formEmail">Email :</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="formEmail"
                                    placeholder="Entrez votre email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor="formPassword">Mot de passe :</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="formPassword"
                                    placeholder="Entrez votre mot de passe"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                            </div>

                            <button disabled={btn} type="submit" className="btn mt-3 btn btn-sub btn-block">
                                Se connecter
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
