import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/App.css';
import NavBar from './components/Repeats/NavBar';
import Footer from './components/Repeats/Footer';
import AnimatedRoutes from './components/AnimatedRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import { existeLang, setLang } from './utils/user';

const App = () => {

  if (!existeLang()) {
    setLang('fr');
  }

  return (
    <div className='app'>
      <NavBar />
      <Router>
        <AnimatedRoutes />
      </Router>
      <Footer />
    </div>
  );
};

export default App;
