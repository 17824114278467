import React from 'react';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3">
            <div className="container text-center">
                <div className='row'>
                    <div className='col col-3'>

                    </div>
                    <span className="text-muted col col-6">© 2024 Jacobs Julien</span>
                    <div className='col col-3'>
                        <a target='_blank' href='https://www.facebook.com/CamerataVocale' style={{ textAlign: 'end' }}>
                            <img src="/assets/fb.svg" alt="Facebook" height={36} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;