import { getUserConnected, userIsConnected } from "../../../utils/user";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";

export default function Book() {

    const { eventId } = useParams();

    const [numberOfPeople, setNumberOfPeople] = useState(1);
    const [reservationType, setReservationType] = useState('regular');
    const [vipRate, setVipRate] = useState(0);
    const [normalRate, setNormalRate] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        loadEvent();
    }, []);

    if (!userIsConnected()) {
        window.location.href = '/login';
        return null;
    }

    const loadEvent = async () => {
        const res = await requestApi('/user/getEventById', 'post', {
            eventId: eventId
        });
        if (res.data.success) {
            setVipRate(res.data.data.vip_prize);
            setNormalRate(res.data.data.reg_prize);
        }
    }

    const handleNumberOfPeopleChange = (e) => {
        setNumberOfPeople(e.target.value);
    };

    const handleReservationTypeChange = (e) => {
        setReservationType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const res = await requestApi('/user/createReservation', 'post', {
            userId: getUserConnected(),
            type: reservationType,
            eventId: eventId,
            nbPersons: numberOfPeople,
        });

        if (!res.data.success) {
            setModalShow(true);
            setModalContent(res.data.msg);
            console.log(res.data.msg);
        }
        else {
            window.location.href = res.data.data.url;
        }
    }

    return (
        <div className="container mt-5 mb-5">
            <ModalCustom content={modalContent} show={modalShow} onHide={() => { setModalShow(false) }} />
            <div style={{ maxWidth: '460px', margin: 'auto' }}>
                <form onSubmit={handleSubmit} className="form-custom p-3">
                    <div className="mb-3">
                        <h2>Réservation</h2>
                        <label htmlFor="numberOfPeople" className="form-label">
                            Nombre de personnes
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="numberOfPeople"
                            value={numberOfPeople}
                            onChange={handleNumberOfPeopleChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="reservationType" className="form-label">
                            Type de réservation
                        </label>
                        <select
                            className="form-select"
                            id="reservationType"
                            value={reservationType}
                            onChange={handleReservationTypeChange}
                        >
                            <option value="regular">Normal ({normalRate} €)</option>
                            <option value="vip">VIP ({vipRate} €)</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Tarif total</label>
                        <p className="form-control-static">
                            {reservationType === 'regular'
                                ? numberOfPeople * normalRate
                                : numberOfPeople * vipRate}{' '}
                            €
                        </p>
                    </div>

                    <button type="submit" className="btn btn-sub">
                        Réserver
                    </button>
                </form>
            </div>
        </div>
    );
};