import { motion } from "framer-motion";

export default function MotionPage({ content }) {
    return (
        <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {
                content
            }
        </motion.div>
    );
}