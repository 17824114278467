import { useState } from "react"
import { requestApi } from "../../../utils/request";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Article from "./Article/Article";

export default function Home() {

    const [articles, setArticles] = useState([]);
    const { offset } = useParams();

    const loadArticles = async () => {
        const res = await requestApi('/user/getArticles', 'get', {});
        if (res.data.success) {
            setArticles(res.data.data);
            regrouperArticlesParId(articles);
        } else {
            alert(res.data.msh);
        }
    }

    useEffect(() => {
        loadArticles();
    }, []);

    console.log(articles);

    return (
        <div className="container mt-3">
            {
                articles.map((article) => {
                    return <Article key={article.id} article={article} />
                })
            }
        </div>
    );
}

function regrouperArticlesParId(tableau) {
    const resultat = {};

    tableau.forEach(article => {
        const article_id = article.article_id;

        if (!resultat[article_id]) {
            resultat[article_id] = [];
        }

        resultat[article_id].push(article);
    });

    return Object.values(resultat);
}