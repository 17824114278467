import { useEffect } from "react";
import { useState } from "react"
import { requestApi } from "../../../utils/request";
import MemberCard from "./MemberCard";

export default function Members() {

    const [members, setMembers] = useState([]);

    const loadMembers = async () => {
        let res = await requestApi('/user/getMembers', 'post', {});

        if (res.data.success) {
            setMembers(res.data.data);
        }
    }

    useEffect(() => {
        loadMembers();
    }, []);

    return (
        <div className="container bg-beige rounded p-3 mt-5">
            <h1 className="fs-2">
                Les membres de notre équipe:
            </h1>
            <div className="row">
                {
                    members.map((member) => {
                        return (
                            <MemberCard member={member} />
                        );
                    })
                }
            </div>
        </div>
    )
}