import React from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Contact';
import Login from './Pages/Connexion/Login/Login';
import { AnimatePresence } from 'framer-motion';
import MotionPage from './MotionPage';
import Members from './Pages/Members/Members';
import History from './Pages/History/History';
import BookEvent from './Pages/book/BookEvent';
import Book from './Pages/book/Book';

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<MotionPage key="home" content={<Home />} />} />
                <Route exact path='/contact' element={<MotionPage key="contact" content={<Contact />} />} />
                <Route exact path='/login' element={<MotionPage key="login" content={<Login />} />} />
                <Route exact path='/members' element={<MotionPage key="members" content={<Members />} />} />
                <Route path='/home/:offset' element={<MotionPage key={"home"} content={<Home />} />} />
                <Route exact path='/history' element={<MotionPage key={'history'} content={<History />} />} />
                <Route path='/book' element={<MotionPage key={'book'} content={<Book />} />} />
                <Route path='/book/:eventId' element={<MotionPage key={'book'} content={<BookEvent />} />} />
            </Routes>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;