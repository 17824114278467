import { useState } from "react";
import Footer from "../../Repeats/Footer";
import NavBar from "../../Repeats/NavBar";
import { Form, Button, Container } from 'react-bootstrap';
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";

export default function () {

    const [content, setContent] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [category, setCategory] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await requestApi('/sendMessage', 'post', {
            fullname: fullName,
            email: email,
            content: content,
            requestType: category
        });

        if (!res.data.success) {
            setModalContent(res.data.msg);
            setModalShow(true);
        } else {
            setContent('');
            setEmail('');
            setFullName('');
            setCategory(null);
            setModalContent('Votre demande a bien été envoyée. Vous recevrez une réponse par email dans les 48 heures.');
            setModalShow(true);
        }
    }

    return (
        <>
            <ModalCustom show={modalShow} content={modalContent} onHide={() => { setModalShow(false); }} />
            <div className="p-3 mt-5 mb-5 rounded container">
                <Container>
                    <Form onSubmit={handleSubmit} className="ContCustom rounded form-custom p-3">
                        <h1 className="mt-3 t-bold">
                            Formulaire de contact
                        </h1>
                        <Form.Group controlId="formName" className="mt-3">
                            <Form.Label>Votre Nom</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez votre nom et prénom"
                                name="name"
                                value={fullName}
                                onChange={(e) => { setFullName(e.target.value) }}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Label>Votre Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Entrez votre email"
                                name="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>
                                Type de demande
                            </Form.Label>
                            <Form.Select required onChange={(e) => { setCategory(e.target.value) }}>
                                <option>
                                    --Selectionnez votre categorie--
                                </option>
                                <option value={"Demande d'emplois"}>
                                    Demande d'emplois
                                </option>
                                <option value={"Concernant les dons"}>
                                    Concernant les dons
                                </option>
                                <option value={"Autre"}>
                                    Autre
                                </option>
                            </Form.Select>

                        </Form.Group>
                        <Form.Group controlId="formMessage" className="mt-3">
                            <Form.Label>Votre Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder="Entrez votre message"
                                name="message"
                                value={content}
                                onChange={(e) => { setContent(e.target.value) }}
                                required
                            />
                        </Form.Group>

                        <button className="mt-3 btn btn-sub" type="submit">
                            Envoyer
                        </button>
                        <h2 className="mb-3 mt-3 t-bold">
                            Autre de contact.
                        </h2>
                        <h3>
                            Philippe De Clerck
                        </h3>
                        <a href="mailto:info@cameratavocale.be" className="link">info@cameratavocale.be</a>
                        <br />
                        <a href="tel:+32478887235" className="link">+32 (0) 478 / 88.72.35</a>
                    </Form>
                </Container>
            </div>
        </>
    )
}