import { useEffect } from "react";
import { getLang } from "../../../../utils/user";
import { useState } from "react";

export default function Paragraphe({ element }) {

    const [text, setText] = useState('');

    useEffect(() => {
        switch (getLang()) {
            case 'fr':
                setText(element.text_content_fr);
                break;
            case 'nl':
                setText(element.text_content_nl);
                break;
            case 'en':
                setText(element.text_content_en);
                break;
        }
    })

    return (
        <p style={{ fontSize: element.font_size + 'em', textAlign: element.text_align, color: element.text_color }}>
            {text}
        </p>
    )
}