import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalCustom = ({ show, content, onHide }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(show);
    }, [show]);

    const handleClose = () => {
        setShowModal(false);
        if (onHide) {
            onHide(); // Appeler la fonction de rappel lors de la fermeture du modal
        }
    };

    const handleShow = () => {
        setShowModal(true);
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose} onShow={handleShow}>
                <Modal.Header closeButton>
                    Serveur info
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalCustom;
