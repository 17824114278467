import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { disconnect, setLang, userIsConnected } from '../../utils/user';
import { url_api } from '../../utils/config';

export default function NavBar() {
    const { t } = useTranslation();
    const [userConnected, setUserConnected] = useState(false);

    useEffect(() => {
        if (userIsConnected()) {
            setUserConnected(true);
        }
    }, []);

    return (
        <Navbar className='fs-4 bg-beige navBar' expand="lg">
            <div className="container">
                <Navbar.Brand className='fs-3' href="/"><img height={'64px'} width={'auto'} src={url_api + '/assets/pictures/base/logo.png'} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarNav" />
                <Navbar.Collapse id="navbarNav">
                    <Nav className="ms-auto">

                        <Nav.Link href="/" className='link-hover'>Accueil</Nav.Link>

                        <NavDropdown title="Concerts" id="services-dropdown" className='link-hover'>
                            <NavDropdown.Item style={{ background: 'rgba(0,0,0,0)' }} className='link-hover' href="book">Réservation</NavDropdown.Item>
                            <NavDropdown.Item style={{ background: 'rgba(0,0,0,0)' }} className='link-hover' href="events">Nos évènements</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="À Propos" id="about-dropdown">
                            <NavDropdown.Item style={{ background: 'rgba(0,0,0,0)' }} className='link-hover' href="about">Qui sommes nous</NavDropdown.Item>
                            <NavDropdown.Item style={{ background: 'rgba(0,0,0,0)' }} className='link-hover' href="history">Historique</NavDropdown.Item>
                            <NavDropdown.Item style={{ background: 'rgba(0,0,0,0)' }} className='link-hover' href="members">Membres</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link href="contact" className='link-hover'>Contact</Nav.Link>

                        <Nav.Item className='nav-item'>
                            {userConnected ? <button className='btn btn-custom fs-4' onClick={disconnect}>{t('Déconnexion')}</button> : <Nav.Link className='link-hover' href='/login'>{t('connexion')}</Nav.Link>}
                        </Nav.Item>
                        <button onClick={() => { setLang('fr'); window.location.reload(); }} className='btn-lang'>FR</button>
                        <button onClick={() => { setLang('nl'); window.location.reload(); }} className='btn-lang'>NL</button>
                        <button onClick={() => { setLang('en'); window.location.reload(); }} className='btn-lang'>EN</button>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}