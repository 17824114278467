import { useState } from "react";
import { getLang } from "../../../../utils/user";
import { useEffect } from "react";

export default function TitleCell({ element }) {

    const [text, setText] = useState('');

    useEffect(() => {
        switch (getLang()) {
            case 'fr':
                setText(element.text_content_fr);
                break;
            case 'nl':
                setText(element.text_content_nl);
                break;
            case 'en':
                setText(element.text_content_en);
                break;
        }
    }, element);

    return (
        <h1 style={{ textAlign: element.text_align, fontSize: element.font_size + 'em', color: element.text_color }}>
            {text}
        </h1>
    )
}