import Paragraphe from "./Paragraphe";
import ImageCell from "./ImageCell";
import TitleCell from "./TitleCell";
import LinkCell from "./LinkCell";

export default function Article({ article }) {

    const components = article.components;

    const date = new Date(article.create_at);

    // Extraire l'année, le mois et le jour
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // +1 car les mois commencent à 0
    var day = ("0" + date.getDate()).slice(-2);

    // Nouvelle chaîne de date formatée "YYYY-MM-DD"
    var formattedDate = day + "-" + month + "-" + year;

    return (
        <div className="article p-3 bg-beige rounded">
            {
                components.map((component) => {
                    switch (component.type) {
                        case 'p':
                            return <Paragraphe element={component} />
                        case 'img':
                            return <ImageCell element={component} />
                        case 'h1':
                            return <TitleCell element={component} />
                        case 'a':
                            return <LinkCell element={component} />
                    }
                })
            }
            <p style={{ textAlign: 'end', fontSize: '2em', marginBottom: '60px' }}>Publié le {formattedDate}</p>
            <hr />
        </div>
    )
}