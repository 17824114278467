import { useState } from "react";
import ModalCustom from "../../Repeats/Modal";
import { requestApi } from "../../../utils/request";
import { useEffect } from "react";

export default function Book() {

    const [events, setEvents] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [eventId, setEventId] = useState('');

    const handleSub = async (e) => {
        e.preventDefault();

        const res = await requestApi('/user/selectEvent', 'post', {
            eventId: eventId,
        });

        if (!res.data.success) {
            setModalShow(true);
            setModalContent(res.data.msg);
        } else {
            window.location.href = '/book/' + res.data.data.eventId;
        }
    }

    const loadEvent = async () => {
        const res = await requestApi('/user/getEvents', 'get', {});
        if (res.data.success) {
            setEvents(res.data.data);
        }
    }

    useEffect(() => {
        loadEvent();
    }, []);

    return (
        <div className="container mt-5">
            <ModalCustom content={modalContent} show={modalShow} onHide={() => { setModalShow(false) }} />
            <div style={{ maxWidth: '460px', margin: 'auto' }} className="form-custom p-3">
                <form onSubmit={handleSub}>
                    <select className="form-control" required value={eventId} onChange={(e) => { setEventId(e.target.value) }}>
                        <option>--Selectionnez un évènement--</option>
                        {events.map((event) => {
                            return (
                                <option value={event.id} key={event.id}>{event.event_name}</option>
                            );
                        })}
                    </select>
                    <button type="submit" className="btn-sub mt-3 btn">Continuer</button>
                </form>
            </div>
        </div>
    );
}