export default function History() {

    return (
        <div className="container mt-5">
            <h1>
                Historique
            </h1>
            <div className="row mt-5">
                <div className="col col-12 col-lg-6">
                    <p className="paragraph">
                        L'Ensemble "Camerata Vocale et Instrumentale" fut créé en 2007 par son directeur musical Philippe De Clerck.

                        L'ensemble se produit à l'occasion de plusieurs concerts annuels avec des oeuvres pour solistes vocaux, choeurs et orchestre.

                        Le répertoire principalement baroque, classique et romantique est interprété sur instruments anciens en respectant les tendances qui caractérisent les interprétations de ces époques.

                        La composition des choeurs et de l'orchestre est variable et le choix des musiciens se fait en fonctions de la partition. Les chanteurs sont issus des écoles de musique nationales renommées et internationales, dans de nombreux cas.

                        Tous les instrumentistes sont professionnels et travaillent régulièrement avec les chefs de renommée belges et étrangers.

                        Les concerts sont organisés en collaboration avec des associations philanthropiques, qui bénéficient des bénéfices qui résultent de ces  manifestations.

                        "Camerata Vocale et Instrumentale" cherche également à promouvoir de jeunes solistes issus des conservatoires ou écoles de musique de notre pays.
                    </p>
                </div>
                <div className="col col-12 col-lg-6">
                    <img src="./assets/hist_1.png" alt="IMG PAST" width={'100%'} height={'auto'} />
                    <br />
                    <br />
                    <img src="./assets/hist_2.jpg" className="mb-5" alt="IMG PAST" width={'100%'} height={'auto'} />
                </div>
            </div>
        </div>
    );
}